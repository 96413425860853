<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`${tableName}`" style="table-layout: auto">
      <thead>
        <tr>
          <th class="border px-4 py-3 d-flex align-items-center">
            <!--            <div-->
            <!--              v-if="tableName === 'fields'"-->
            <!--              class="min-w-20px"-->
            <!--              style="flex-basis: 10%"-->
            <!--            >-->
            <!--              <div-->
            <!--                v-if="templateDetails?.template_id"-->
            <!--                class="float-left form-check form-check-sm form-check-custom form-check-solid min-w-20px min-h-20px"-->
            <!--              >-->
            <!--                <input-->
            <!--                  :disabled="!items.length"-->
            <!--                  class="form-check-input"-->
            <!--                  type="checkbox"-->
            <!--                  @click="(event) => toggleSelectAll(event.target.checked)"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->
            <span
              class="text-center text-capitalize font-weight-bolder font-size-h5"
              style="flex-basis: 80%"
            >
              {{ tableName }}</span
            >
            <button
              v-if="tableName === 'templates'"
              @click="() => handleCreateTemplate()"
              class="float-right pa-0 btn btn-icon-info ms-8 ms-lg-16"
            >
              <span class="svg-icon ma-0">
                <v-icon size="24">mdi-plus-box-outline</v-icon>
              </span>
            </button>
          </th>
        </tr>
        <tr>
          <th class="border px-4 py-2">
            <input
              :id="`search-${tableName}`"
              type="search"
              class="border custom-input rounded w-100 py-2 px-3 text-dark-75"
              @keyup="filterInputs"
              :placeholder="`Search ${tableName}..`"
            />
          </th>
        </tr>
      </thead>
      <tbody :class="`${tableName}-tb`">
        <template v-if="items.length === 0">
          <tr>
            <td class="border px-4 py-2">{{ label }}</td>
          </tr>
        </template>
        <template v-else>
          <div class="list-container">
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="d-block w-100"
            >
              <td
                class="border px-4 py-2 d-flex justify-space-between align-center"
              >
                <div class="d-flex align-center">
                  <template v-if="tableName === 'templates'">
                    <span class="font-size-lg py-1 font-weight-medium ml-2">
                      {{ item.name }}
                    </span>
                  </template>

                  <template v-else-if="tableName === 'fields'">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
                    >
                      <input
                        :disabled="!templateDetails?.template_id"
                        class="form-check-input"
                        type="checkbox"
                        v-model="item.is_selected"
                        @click="
                          (event) => toggleSelect(event.target.checked, item)
                        "
                      />
                    </div>
                    <span class="font-size-lg py-1 font-weight-medium ml-2">
                      {{ item.text }}
                    </span>
                  </template>
                </div>
                <div class="d-flex align-center justify-end">
                  <ActionsMenu
                    v-if="tableName === 'templates'"
                    :item="item"
                    @handleEditTemplate="
                      (template) => handleCreateTemplate(template)
                    "
                  />

                  <button
                    :disabled="item?.is_selected === false"
                    @click="(event) => handleNextPress(event, item, index)"
                    class="btn btn-sm btn-icon btn-circle btn-bg-light btn-hover-light-info"
                    :class="`${tableName}-tb-${item.id}-next-btn`"
                  >
                    <span class="svg-icon">
                      <v-icon size="19"> mdi-arrow-right </v-icon>
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </div>
        </template>
      </tbody>
    </table>
  </section>
</template>

<script>
import ActionsMenu from "@/own/components/warehouseManagement/scanningConfigurationTemplates/ActionsMenu.vue";
import {
  ADD_REMOVE_FIELD_TO_SELECTED_TEMPLATE,
  SET_SELECTED_TEMPLATE_DETAILS,
} from "@/core/services/store/scanningConfigurationTemplates.module";

export default {
  name: "NestedDataTable",
  components: { ActionsMenu },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    handleGetFunction: {
      type: Function,
      required: false,
    },
    handleCreateTemplate: {
      type: Function,
      required: false,
    },
  },
  computed: {
    templateDetails: function () {
      return this.$store.getters.getSCANCONFIGTEMPLATESelectedTemplateDetails;
    },
    items: function () {
      if (this.tableName === "templates")
        return this.$store.getters.getSCANCONFIGTEMPLATETemplates;
      else return this.$store.getters.getSCANCONFIGTEMPLATEFields;
    },
    label: function () {
      if (this.tableName === "templates")
        return this.$store.getters.getSCANCONFIGTEMPLATETemplateLabel;
      else return this.$store.getters.getSCANCONFIGTEMPLATEFieldLabel;
    },
  },
  methods: {
    // toggleSelectAll(isChecked) {
    //   this.$store.commit(SELECT_ALL_FIELDS, isChecked);
    //
    //   this.$store.commit(SET_SELECTED_TEMPLATE_DETAILS, {
    //     showForm: false,
    //   });
    // },
    toggleSelect(isChecked, selectedField) {
      this.$store.commit(ADD_REMOVE_FIELD_TO_SELECTED_TEMPLATE, {
        selectedField,
        forAdding: isChecked,
      });

      this.$store.commit(SET_SELECTED_TEMPLATE_DETAILS, {
        showForm: false,
      });
    },
    handleNextPress(event, item, index) {
      if (this.tableName === "templates") {
        this.handleGetFunction(item, event, this.tableName, index);
      } else if (this.tableName === "fields") {
        this.handleGetFunction(
          item,
          event,
          this.tableName,
          this.templateDetails.template_index
        );
      }
    },
    filterInputs() {
      let input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("search-" + this.tableName);
      filter = input.value.toUpperCase();
      table = document.getElementById(this.tableName);
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) === -1) {
            tr[i].classList.replace("d-block", "d-none");
          } else {
            tr[i].classList.replace("d-none", "d-block");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
td:hover {
  background: #fafafa;
}

.custom-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  outline: none;
  font-weight: normal;
}

.custom-input:focus {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width:nth-child(3) {
    width: 40%;
  }

  .custom-width {
    width: 30%;
  }
}

.list-container {
  overflow-y: scroll;
  max-height: 36vh !important;
}

@media (min-width: 1550px) {
  .list-container {
    max-height: 42vh !important;
  }
}

@media (min-width: 1800px) {
  .list-container {
    max-height: 48vh !important;
  }
}

@media (min-width: 2000px) {
  .list-container {
    max-height: 52vh !important;
  }
}

@media (min-width: 2200px) {
  .list-container {
    max-height: 56vh !important;
  }
}
</style>
