<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ Boolean(item) ? "Edit Template" : "Add New Template" }}
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div
            class="col-12 h-75px"
            v-if="serverData.hasOwnProperty('organizations')"
          >
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.module_id"
              label="Module"
              item-text="text"
              item-value="index"
              :items="serverData.modules"
              clearable
              outlined
              dense
              :error-messages="module_idErrors"
              @input="$v.formData.module_id.$touch()"
              @blur="$v.formData.module_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              item-text="text"
              item-value="index"
              :items="statusItems"
              clearable
              outlined
              dense
              :error-messages="statusErrors"
              @input="$v.formData.status.$touch()"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            />
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { GET_DATA_SCAN_CONFIG_TEMPLATE } from "@/core/services/store/scanningConfigurationTemplates.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreateTemplate",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.serverData.hasOwnProperty("organizations");
        }),
      },
      module_id: { required },
      name: { required },
      status: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      organization_id: null,
      module_id: null,
      name: null,
      status: 1,
      description: null,
    },
    item: null,
  }),
  methods: {
    toggleModal(item = null) {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.item = item;
        this.formData = { ...item };
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v?.formData[fieldName]?.$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_PAGE_LOADING, true);

      if (this.item) {
        await ApiService.post(
          `/warehouse_management/custom/scanning_configuration_templates/update`,
          {
            ...this.item,
            organization_id: this.formData.organization_id,
            module_id: this.formData.module_id,
            name: this.formData.name,
            status: this.formData.status,
            description: this.formData.description,
            fields: this.item.fields.map((field) => ({
              ...field,
              is_master_scan: Boolean(field.is_master_scan),
              is_required: Boolean(field.is_required),
              is_editable: Boolean(field.is_editable),
              is_parseable: Boolean(field.is_parseable),
              is_persist_input_value: Boolean(field.is_persist_input_value),
            })),
          }
        )
          .then(() => {
            swalAdded();
            this.toggleModal();
          })
          .finally(() => {
            this.$store.dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
        // }
      } else {
        await ApiService.post(
          `/warehouse_management/custom/scanning_configuration_templates/store`,
          {
            ...this.formData,
            fields: [],
          }
        )
          .then(() => {
            swalAdded();
            this.toggleModal();
          })
          .finally(() => {
            this.$store.dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
      }
    },
    resetFormData() {
      this.formData = {
        organization_id: null,
        module_id: null,
        name: null,
        description: null,
        status: 1,
      };
    },
  },
  computed: {
    serverData: function () {
      return this.$store.getters.getSCANCONFIGTEMPLATEServerData;
    },
    statusItems: function () {
      return [
        { index: 0, text: "Inactive" },
        { index: 1, text: "Active" },
      ];
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id");
    },
    module_idErrors: function () {
      return this.handleFormValidation("module_id");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
  },
};
</script>
