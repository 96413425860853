<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          class="text-center bg-hover-light-info"
          @click.prevent="() => $emit('handleEditTemplate', item)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Edit</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          class="text-center bg-hover-light-info"
          @click.prevent="() => removeItem(item.id)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Delete</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          class="text-center bg-hover-light-info"
          @click.prevent="() => download(item.id)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Download</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { GET_DATA_SCAN_CONFIG_TEMPLATE } from "@/core/services/store/scanningConfigurationTemplates.module";

export default {
  name: "ActionsMenu",
  props: ["item"],
  // data: () => ({
  //   editCountry: false,
  //   editState: false,
  //   editCity: false,
  //   editArea: false,
  // }),
  methods: {
    download(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/custom/scanning_configuration_templates/export",
        {
          id,
        }
      ).then(({ data }) => {
        // console.log(data.url);
        const link = document.createElement("a");
        link.href = data.url;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        // Swal.fire("Deleted!", "Record has been deleted.", "success");
      });
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post(
            "/warehouse_management/custom/scanning_configuration_templates/destroy",
            {
              id,
            }
          )
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
            })
            .finally(() => {
              this.$store
                .dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE)
                .finally(() => {
                  this.$store.commit(SET_PAGE_LOADING, false);
                });
            });
        }
      });
    },
  },
};
</script>
