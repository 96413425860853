<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-10 mt-6 d-flex align-center justify-space-between">
      <datatable-detail class="col-md-9" :table_properties="tableProperties" />

      <div class="justify-content-end px-4">
        <button
          type="submit"
          class="btn btn--export-filter float-end"
          @click.prevent="submitTemplate"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          Submit
        </button>
      </div>
    </div>

    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="templates"
        :handleGetFunction="handleTemplateSelect"
        :handleCreateTemplate="handleCreateTemplate"
      />
      <NestedDataTable
        tableName="fields"
        :handleGetFunction="handleFieldSelect"
      />
      <NestedForm ref="nestedForm" />
    </div>
    <CreateTemplate ref="createTemplate" />
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import scanningConfigurationTemplates from "@/own/mixins/scanningConfigurationTemplatesMixin";
import NestedDataTable from "@/own/components/warehouseManagement/scanningConfigurationTemplates/NestedDatatable.vue";
import CreateTemplate from "@/own/components/warehouseManagement/scanningConfigurationTemplates/CreateTemplate.vue";
import NestedForm from "@/own/components/warehouseManagement/scanningConfigurationTemplates/NestedForm.vue";

export default {
  name: "ScanningConfigurationTemplates",
  components: {
    NestedForm,
    NestedDataTable,
    CreateTemplate,
    DatatableDetail,
  },
  mixins: [scanningConfigurationTemplates],
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
